<template>
  <v-popover :disabled="!options">
    <slot>
      <button class="downloads-btn btn px-4 pr-sm-5">
        {{ $t('Download') }}
        <Icon class="position-absolute" family="fas" name="chevron-down" />
      </button>
    </slot>

    <template #popover>
      <div class="downloads__download-button-popover">
        <Loader v-if="isLoading" class="position-absolute" loading full />
        <ul v-for="(option, optionIndex) in options" :key="optionIndex">
          <p>{{ option.title }}</p>

          <li
            v-for="(link, linkIndex) in option.links"
            :key="linkIndex"
            v-close-popover
          >
            <a href="#" @click.prevent="onDownload($event, link)">
              <Icon
                v-if="link.icon"
                :family="link.iconFamily || 'fab'"
                :name="link.icon"
              />

              <component :is="link.svg" v-else-if="link.svg" />

              <div class="d-flex align-items-center">
                <h4>{{ link.title }}</h4>
                <span v-if="link.description">{{ link.description }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Loader from 'common/components/Loader.vue';
import Icon from 'common/components/Icon';

export default {
  name: 'DownloadButton',
  components: { Icon, Loader },
  props: {
    options: {
      required: true,
      type: Array,
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['core/getDownloads'];
    },
    downloadLinks() {
      return this.$store.state.core.downloadLinks;
    },
  },
  methods: {
    async onDownload(e, link) {
      e.preventDefault();

      const success = await this.$store.dispatch('core/download', link);
      if (!success) return;

      this.$emit('download', link);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.v-popover .trigger {
  width: 100%;
}

button.downloads-btn {
  padding-left: 1.125rem;
  padding-right: 2.25rem;
  position: relative;
  color: var(--color-primary);
  border: 1px solid var(--border-color);

  i {
    color: var(--text-muted);
    font-size: 14px;
    right: 16px;
    transform: translateY(-50%);
    top: 50%;
  }
}
</style>
